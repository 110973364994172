// Inter
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter-Light'), url('/assets/fonts/Inter/static/Inter-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter-Light'), url('/assets/fonts/Inter/static/Inter-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'), url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Medium'), url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter-Semibold'), url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter-Thin'), url('/assets/fonts/Inter/static/Inter-Thin') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local('Inter-ExtraBold'), url('/assets/fonts/Inter/static/Inter-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: local('Inter-Black'), url('/assets/fonts/Inter/static/Inter-Black.ttf') format('opentype');
}

// Yeseva One
@font-face {
  font-family: 'Yeseva One';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'), url('/assets/fonts/Yaseva-One/YesevaOne-Regular.ttf') format('opentype');
}

// Philosopher
@font-face {
  font-family: 'Philosopher';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'), url('/assets/fonts/Philosopher/Philosopher-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Philosopher';
  font-style: italic;
  font-weight: 400;
  src: local('Inter-RegularItalic'), url('/assets/fonts/Philosopher/Philosopher-Italic.ttf') format('opentype');
}

@font-face {
  font-family: 'Philosopher';
  font-style: normal;
  font-weight: 700;
  src: local('Inter-Bold'), url('/assets/fonts/Philosopher/Philosopher-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Philosopher';
  font-style: italic;
  font-weight: 700;
  src: local('Inter-BoldItalic'), url('/assets/fonts/Philosopher/Philosopher-BoldItalic.ttf') format('opentype');
}

// Oswald

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: local('Oswald-ExtraLight'), url('/assets/fonts/Oswald/Oswald-ExtraLight.ttf') format('opentype');
}


@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald-Light'), url('/assets/fonts/Oswald/Oswald-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald-Regular'), url('/assets/fonts/Oswald/Oswald-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald-Medium'), url('/assets/fonts/Oswald/Oswald-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: local('Oswald-Semibold'), url('/assets/fonts/Oswald/Oswald-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald-Bold'), url('/assets/fonts/Oswald/Oswald-Bold.ttf') format('opentype');
}
