// Global PrimeNG custom styles
.btn-primary-500-xs {
  @apply border-branding-primary-500 bg-branding-primary-500 text-xs text-palette-base-white;
}
.btn-primary-600-xs {
  @apply border-branding-primary-600 bg-branding-primary-600 text-xs text-palette-base-white;
}
.btn-primary-700-xs {
  @apply border-branding-primary-700 bg-branding-primary-700 text-xs text-palette-base-white;
}

//*************************Login **************************
.flex-container {
  display: flex;
  flex: 90%;
  align-items: center;
}

.login-container {
  @apply flex flex-col w-full gap-5 mt-8;

  .login-username-input {
      @apply w-full min-h-[56px] border rounded-lg;
  }

  .sign-in-btn {
      @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;

      .p-button:hover {
          @apply bg-inherit;
      }
  }

  .sign-in-btn:hover {
      @apply bg-branding-primary-600;
  }

  .sign-in-btn:focus {
      box-shadow: none !important;
  }

  .p-inputtext:focus {
      @apply border-branding-primary-300 outline-none;
      box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .p-inputtext:hover {
      @apply border-branding-primary-300;
  }

  .p-button.p-button-secondary:enabled:focus {
      box-shadow: unset;
  }

  .login-phone-dropdown {
      @apply ml-2.5;

      .p-dropdown-panel {
          @apply w-[260px] bg-white;
      }

      .p-overlay {
          @apply mt-4 ml-[-12px];
      }

      .p-dropdown {
          @apply flex items-baseline;
      }

      .p-component {
          @apply border-none h-10;
      }

      .p-dropdown-items-wrapper {
          @apply bg-white text-sm min-h-[240px] border-system-gray-200;
      }
  }

  .password-input::placeholder {
      @apply text-xs text-system-gray-500;
  }

  .password-input::-ms-reveal {
      @apply hidden;
  }

  .login-phone-input {
      @apply border-none w-full;
  }
}

//************************************************************
//***************************Sign up*********************************
.background {
  @apply h-auto bg-cover object-contain flex;
  background-image: url('/assets/images/Background.svg');
}

.container {
  @apply hidden;
}

.sign-up-flex-container {
  @apply hidden;
}

.sign-up-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.flex-container {
  display: flex;
  flex: 90%;
  align-items: center;
  justify-content: flex-end;
}

.sign-up-password-input::placeholder {
  @apply text-xs text-system-gray-400;
}

.sign-up-password-input::-ms-reveal {
  @apply hidden;
}

.area-input {
  @apply border-none w-full;
}

.verification-form {
  @apply h-12 flex flex-row justify-start items-start gap-4 p-0;

  .p-inputtext::placeholder {
      @apply text-center text-base;
  }
}

.verification-input {
  @apply h-12 w-14 text-center font-bold;
}

.resend-button {
  @apply bg-white border-none cursor-pointer font-bold text-branding-primary-700;
}

.sign-up-btn {
  @apply w-full bg-branding-primary-700 border border-branding-primary-300 rounded-lg;

  .p-button:hover {
      @apply bg-inherit;
  }

  .p-button:enabled:active {
      @apply border-transparent bg-branding-primary-700;
  }
}

.p-inputtext {
  @apply w-full min-h-[48px] rounded-lg;
}

.p-inputtext:focus {
  border-color: #84caff;
  outline: none;
  box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.p-inputtext:hover {
  @apply border-branding-primary-300;
}

.sign-up-form {
  @apply flex flex-col gap-4 mt-5;

  .policy-checkbox {
      .p-checkbox .p-checkbox-box {
          @apply rounded-lg;
      }
  }

  .area-dropdown {
      @apply ml-2.5;

      .p-dropdown-panel {
          @apply w-[260px] bg-white;
      }

      .p-overlay {
          @apply mt-4 ml-[-12px];
      }

      .p-dropdown {
          @apply flex items-center;
      }

      .p-component {
          @apply border-none h-10;
      }

      .p-dropdown-items-wrapper {
          @apply bg-white text-sm min-h-[240px] border-system-gray-200;
      }
  }

  .flex-input {
      @apply flex flex-col gap-1;

      input {
          @apply w-full;
      }
  }

  .p-inputtext:focus {
      border-color: #84caff;
      outline: none;
      box-shadow: 0 0 0 4px #eaf3ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  .p-inputtext:hover {
      @apply border-branding-primary-300;
  }
}

@media (min-width: 768px) {
  .container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
  }

  .sign-up-container {
      flex: 1.25;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
  }
}

// ************************ Header *********************
.language-setting.p-dialog .p-dialog-content {
  padding: 0 1.5rem 2rem 1.5rem !important;
}

.custom-noti.p-overlay img.w-11 {
  min-width: 2.75rem;
}

.custom-noti.p-overlay .text-sm {
  text-wrap: wrap;
}

.custom-mega-menu .grid{
  margin: 0px !important;
}

.language-setting.p-dialog  .p-dialog-content{
  overflow-y: unset !important;
}

#app-header .top-\[115\%\] {
  top:0px !important
}

.language-setting.p-dialog .p-dropdown .p-dropdown-trigger {
  margin-right: 10px;
}

.language-setting.p-dialog {
  height: fit-content !important;
}
#menu-bar .custom-mega-menu.p-overlay.p-component {
 top: 20px !important;

}

#menu-bar .custom-noti.p-overlay.p-component {
  top: 25px !important;

}

.create-account.p-dialog {
  height: fit-content !important;
}
// .gray-header .text-palette-gray-500{
//     color: rgb(234 236 240) !important;

// }
// .gray-header  span, .gray-header i{
//     color: rgb(234 236 240) !important;
// }

// .gray-header .p-overlay span, .gray-header .p-overlay:not(.booking-option-overlay) .p-overlay-content i,
// .gray-header  a:hover span, .gray-header .p-overlay:not(.booking-option-overlay) a:hover i {
//     color: rgb(55, 58, 64) !important;
// }
.text-palette-blue-600 i.sctr-icon-chevron-down{
  color: #1570ef !important;
}

.error-language.p-dialog-mask{  
  height: 100vh !important;
}
.default-lang-dialog.p-dialog .p-dialog-content {
  padding: 1.5rem !important;
}


#app-header .custom-mega-menu-mobile.overflow-y-auto, #app-header .custom-noti-mobile.overflow-y-auto{
  overflow-y: unset !important;
}

#app-header .custom-noti-mobile .w-11{
  min-width: 2.75rem !important;

}
#app-header #noti-container{
  scrollbar-gutter: stable;
  max-height: 100vh;
}

#app-header .booking-option-overlay{
  white-space: nowrap !important;
}


//************************************************************
.booking-menu {
  ::-webkit-scrollbar {
      width: 7px !important;
  }
}

.range-picker .p-calendar .p-datepicker {
  border: none;
  padding: 0;
}

.range-picker .p-calendar .p-datepicker .p-datepicker-header {
  border-bottom: none !important;
  padding: 0;

  .p-overlaypanel.p-component {
      left: 0px !important;
  }
}

.range-picker .p-datepicker table td {
  padding: 5px 5px !important;

  .p-highlight {
      background: #1570EF;
      color: #FFFFFF;
  }
}

.p-datepicker table td.p-datepicker-today>span {
  border: 1.5px solid #1570EF;
}

.range-picker .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: none;
}

.range-picker .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-next:focus,
.range-picker .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-prev:focus {
  box-shadow: none !important;
}

// -------------------------------****------------------------------------
// -----------------------------Header------------------------
.assistant-btn {
  background: linear-gradient(98.52deg, #fead34 4.54%, #ff7041 87.05%);
  backdrop-filter: blur(2px);
  border-radius: 6px;
}

.box-icon {
  min-height: 20px;
  min-width: 20px;
  max-width: 20px;
  min-width: 20px;
}

.box-avatar {
  background: linear-gradient(white, white) padding-box, linear-gradient(98.52deg, #fead34 4.54%, #ff7041 87.05%) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
}

.add-destination button.p-button {
  border: none;
  height: 52px !important;
}

#container-search .p-button {
  border: none !important;
  background: none !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none;
}

@media screen and (max-width: 1450px) {

  .text-assistant,
  .box-nickname {
      display: none;
  }
}

.search-input {
  --tw-bg-opacity: 0, transition: width 0.3s ease-in-out !important;
  --webkit-transition: width 0.3s ease-in-out;
}

.tooltip-menu .p-tooltip-text {
  font-size: 13px !important;
}

.tooltip-menu.p-tooltip .p-tooltip-text {
  @apply bg-black bg-opacity-40 font-medium text-sm text-gray-200 mt-3
}

.tooltip-menu .p-tooltip-arrow {
  display: none;
}

.shadow-xs {
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

// -------------------------------------*********--------------------------
//------------------------------------ DateRangePicker--------------------------
.theme-green .bs-datepicker-head {
  background-color: #FFFFFF;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1200 !important;
}

bs-month-calendar-view {
  padding: 16px;
}

bs-years-calendar-view {
  padding: 16px;
}

bs-days-calendar-view:first-of-type {
  border-right: 1px solid #EAECF0;
}

.bs-datepicker-body table.months {
  padding: 0px;
}

.bs-datepicker-body table th {
  color: var(--gray-700, #344054);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 5px;
}

.bs-datepicker-head button:hover {
  padding: 0px 10px 0px 10px !important;
}

.search-map {
  .bs-datepicker {
      left: 52px;
  }
}

.bs-datepicker {
  border-radius: 8px;
  bottom: 8px;
}

.bs-datepicker-body table.days td.active:not(.select-start):before,
.bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #F2F4F7;
}

.bs-datepicker-head button {
  color: black;
}

.bs-datepicker-body table td span.selected {
  background-color: #1570EF;
  color: #FFFFFF !important;
}

.bs-datepicker-body table td {
  padding: 1px;
}

.bs-datepicker-body table td span {
  height: 35px;
  width: 100px;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 10px;
  left: -5px;
}

.bs-datepicker-body table.days span.in-range.select-end:before {
  content: "";
  left: -10px;
  top: 6px;
  bottom: 6px;
  right: 21px;
}

bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  bottom: 6px;
  left: -8px;
  right: -5px;
  top: 6px;
}

.bs-datepicker-container {
  padding: 0px;
}

.bs-datepicker-head {
  min-width: auto;
  height: 25px;
  padding: 0px;
}

bs-days-calendar-view:first-of-type {
  border-right: 1px solid #EAECF0;
}

bs-days-calendar-view {
  width: 328px;
  padding: 24px 16px;
  height: 392px;
}

.bs-datepicker-body table td span.disabled {
  color: #9aaec1 !important;
}

.p-overlaypanel-content {
  padding: 0px !important;
}

.bs-datepicker-body {
  border: none;
  padding: 0px;
}

.bs-datepicker-body:first-child {
  border-left: 1px solid;
}

.ng-trigger.ng-trigger-animation {
  border-radius: 8px;
  transform-origin: top center !important;
}

.search-hotel button {
  position: relative;
  padding: 16px;
  top: 0px;
  left: 0px !important;
  border-radius: 12px !important;
  height: 52px;
}

.search-hotel button span {
  position: relative;
  bottom: 1px;
  left: 0px;
  font-weight: 500;
}

.customTodayClass {
  background-color: #F2F4F7;
  border-radius: 50%;
  border: 1px solid #1570EF;
}

.containerClassCalendar .bs-datepicker-body table td span.selected:after {
  content: "";
  left: 20px;
  right: 15px;
  background: #F2F4F7;
}

.containerClassCalendar .bs-datepicker-body table td span.selected:before {
  content: "";
  left: 25px;
  right: 15px;
  bottom: 20px;
  top: 20px;
  background: #F2F4F7;
}

.containerClassCalendar .bs-datepicker-body table td span {
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
}

.bs-datepicker-body .days.weeks {
  height: 310px;
}

.p-inputtext:enabled:focus {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  border: 1px solid #84CAFF !important;
  background: var(--base-white, #FFF);
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
}

.p-input-icon-left>i:first-of-type {
  top: 23px
}

.bs-datepicker-head button.current {
  font-weight: 600;
}

.bs-datepicker-body table.days span {
  color: var(--gray-700, #344054);
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover {
  color: #D0D5DD;
  background-color: none !important;
}

.buttonText button {
  width: 185px !important;
  max-width: 100%;
  left: 0 !important;
  margin: -7px 0px 0px 0px;
  padding: 0 !important;
  top: 7px !important;
}

.buttonText button span {
  top: 0px !important;
}

.search-available {
  margin-top: 0 !important;
}

@media screen and (max-width: 991px) {
  #container-search {
      height: 100% !important;
      padding: 4px 8px 6px 8px !important;

      .search-hotel {
          top: -16px !important;
          left: 0px !important;
      }
  }

  #container-search .p-button {
      width: 100% !important;
      padding: 10px;
      position: relative;
  }

  .search-hotel {
      padding: 15px 0px;
      left: 0px !important;
  }

  .p-overlaypanel .p-overlaypanel-content {
      width: auto;
  }

  .bs-datepicker-body:first-child {
      border: none;
  }

  .search-box .p-overlaypanel {
      width: 100%;
  }

  .bs-daterangepicker-container {
      width: 75% !important;
  }

  .search-hotel button {
      top: 14px !important;
      left: 0px !important;
      border-radius: 12px !important;
  }

  .dropdown-age .p-dropdown {
      width: 100% !important;
  }

  .container-button {
      max-width: 100% !important;
      margin-top: 15px;
  }

  #container-search .container-place {
      top: 0px !important;
  }

  .container-select-date {
      top: 6px !important;
      margin-top: -6px !important;
  }

  #container-search #selectPeople {
      top: 2px !important;
      margin-top: 6px;
  }

  .search-hotel button span {
      left: 0;
  }

  .search-map {
      .search-hotel button {
          top: 12px !important;
      }
  }
}

@media (min-width:992px) and (max-width:1023px) {
  .containerClassCalendar {
      left: 15px;
      top: 2px;
  }

  .bs-datepicker {
      transform-origin: top center !important;
  }

  .search-hotel button span {
      left: 0;
  }

  #container-num-room {
      width: 100%;
  }

  #container-search .p-button {
      width: 100% !important;
      padding: 14px;
      position: relative;
      top: 13px;
      height: 50px !important;
  }

  .search-hotel {
      padding: 16px 0px;
      border-radius: 12px;
      left: 0px !important;
      top: -13px;
  }

  .search-hotel button {
      top: 11px;
      border-radius: 8px !important;
  }

  .p-overlaypanel .p-overlaypanel-content {
      width: auto !important;
  }

  .ng-trigger.ng-trigger-animation {
      width: 100%;
  }

  .search-hotel button {
      padding: 12px;
      left: 0px !important;
  }

  .dropdown-age .p-dropdown {
      width: 100% !important;
  }

  .container-button {
      max-width: 100% !important;
  }

  .buttonText button {
      top: 15px !important;
  }

  .search-map .search-hotel {
      top: -14px !important;
  }

  #container-search {
      height: 60px !important;
      gap: 2px !important;

      #selectPeople {
          top: 8px !important;
          width: 180px !important;
          max-width: fit-content !important;
      }

      .container-place {
          top: 7px;
          width: max-content !important;
      }

      .search-hotel {
          top: -16px;
          display: math;
          left: 1px !important;
      }
  }

  .search-box .p-input-icon-left {
      width: 100%;
      top: 1px;
      left: -3px
  }

  .container-select-date {
      top: 12px !important;
  }

  #addressPanel {
      width: 500px !important;
  }

  .search-box .p-overlaypanel {
      width: auto !important;
  }

  #container-num-room {
      width: 332px !important;
  }

  .search-map {
      .p-button {
          top: 11px !important;
      }
  }

  .search-available {
      .search-hotel {
          top: -8px !important;
      }

      .buttonText button {
          padding: 15px 6px 15px 6px !important;
          top: 14px !important;
      }

      .buttonText button span {
          top: 0px !important;
      }

      .search-hotel button {
          border-radius: 12px !important;
      }
  }

  .bs-datepicker {
      left: 160px;
  }
}

@media (max-width: 1023px) {
  bs-datepicker-container,
  bs-daterangepicker-container {
    left: 40% !important;
  }
}

@media (max-width: 767px) {
  bs-datepicker-container,
  bs-daterangepicker-container {
    left: 43% !important;
  }
}

@media (min-width:1024px) and (max-width: 1279px) {
  .container-place {
      top: 9px !important;
      width: 330px !important;
  }

  .container-select-date {
      top: 12px !important;
      margin: 0px 5px;
  }

  .container-select-people {
      top: 8px !important;
      width: 330px !important;
  }

  .container-button {
      top: 8px !important;
  }

  .view-detail-search-bar,
  .search-view-content-search-bar {
      .search-hotel {
          display: inline-table;
          top: -4px;
          left: -3px !important;
      }
  }

  .search-hotel {
      display: inline-table;
      top: -11px;
      left: -3px !important;
  }

  .buttonText {
      height: 52px !important;
      top: -12px !important;
  }

  .search-hotel button {
      padding: 15px !important;
      left: 1px !important;
  }

  .search-hotel button span {
      left: 0;
  }

  .search-available {
      .search-hotel button {
          padding: 10px !important;
          left: 0 !important;
      }

      .buttonText button span {
          top: 0px !important;
      }
  }

  .bs-datepicker {
      left: 145px;
  }
}

.search-address-list::-webkit-scrollbar {
  width: 4px !important;
  border-radius: 8px;
}

.icon-maker-pin {
  border-radius: 20px;
  background: #D1E9FF;
}

//---------------------------------------dropdown Age ---------------------
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px 32px 0px 0px;
  position: relative;
}

.dropdown-age .p-dropdown span::before {
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-family: primeicons;
  font-weight: 500;
  line-height: 20px;
}

//'primeicons'
.p-dropdown .p-dropdown-trigger {
  width: 1.25rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  height: 44px !important;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0px !important;
}

.p-dropdown-items-wrapper {
  max-height: 352px !important;
}

.dropdown-age .p-dropdown span {
  padding: 10px 0px 10px 0px;
  text-align: center;
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.dropdown-age .p-dropdown {
  height: 40px !important;
  width: 137px;
  border-radius: 6px;
}

.p-dropdown:not(.p-disabled).p-focus {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  border: 1px solid #84CAFF !important;
  background: var(--base-white, #FFF);
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #84CAFF;
}

// -------------------------------------*********--------------------------
// ------------------------------------- Footer-------------------------------
.footer-section {
  .p-button {
      border: none;
      width: 100%;
      padding: 10px 18px;
      height: 100%;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      text-align: center;
  }

  .p-button:hover {
      background: #1570EF;
      border-color: #1570EF;
  }

  .footer-section .p-button:hover {
      background-color: #1570EF;
  }

  .p-button-label {
      font-weight: 600;
      font-size: 1rem;
  }

  @media screen and (max-width: 1720px) {
      .p-button-label {
          font-size: 0.8rem;
      }
  }

  @media screen and (max-width: 1400px) {
      .p-button-label {
          font-size: 1rem;
      }
  }

  .p-component {
      font-family: "Inter";
  }

  .p-inputtext.ng-dirty.ng-invalid {
      border-color: #ced4da;
  }

  .p-inputtext:hover {
      border-color: #ced4da;
  }

  .text-detail {
      color: rgba(52, 64, 84, 1);
      font-size: 14px;
      font-family: "Inter";
      line-height: 24px;
      font-weight: 400;
      font-style: normal;
  }

  .register-btn button {
      padding: 0 5 0 5 !important;
  }

  .register-btn button:focus {
      box-shadow: none !important;
  }

  .register-btn {
      backdrop-filter: blur(2px);
      border-radius: 6px;
      /* Text md/Semibold */
      border-radius: 8px;
      border: 1px solid var(border-branding-primary-600, #1570EF);
      background: #1570EF;
  }
}

// -------------------------------------*********--------------------------



.confirm-sign-out-btn .p-button.p-button-text {
  background-color: #175cd3 !important; 
  color: #ffffff !important; 
  border-color: transparent; 
}

.cancel-sign-out-btn .p-button.p-button-text {
  background-color: #ffffff !important; 
  color: #344054 !important; 
  border-color: #175cd3 !important; 
}

.py-6 {
   padding-top: 24px !important; 
   padding-bottom: 24px !important; 
}
.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.cancel-language-btn .p-button:enabled:hover,
.confirm-language-btn .p-button:enabled:hover {
  background: #175cd3 !important;
  color: #ffffff !important;
  border-color: transparent !important;

}

.confirm-language-btn  .p-button:focus,
.cancel-language-btn .p-button:focus {
  box-shadow: none !important;
  outline: none !important;

}

.cancel-language-btn .p-button:enabled:hover {
  background: #ffffff !important;
  color: #344054 !important;
  border-color: #d0d5dd !important;
}

.language-setting {
  .p-dropdown-filter-container {
      border: 1px solid #84caff;
      border-radius: 4px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 4px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: #495057;
      margin: 4px;
      border-radius: 6px;
      border: 1px solid #e9ecef;
      background: #e9ecef;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      margin: 6px;
      color: #1570EF !important;
      border-radius: 6px;
      background: #d9d9d9;
  }
}

.default-lang-dialog.p-dialog .p-dialog-header,
.default-lang-dialog.p-dialog .p-dialog-footer,
.default-lang-dialog.p-dialog .p-dialog-content{
  background-color: #ffffff !important; 
}

.default-lang-dialog.p-dialog .p-dialog-content {
   border-bottom-left-radius: 0px !important; 
   border-bottom-right-radius: 0px !important; 
}

.fare-rule-content {
  ol, ul, menu {
    list-style: unset !important;
    margin: unset !important;
    padding-left: 36px !important;
}
table {
  margin-top: 5px;
  width: 100%;
}
table tr:first-child th{
  background: #eaecf0;
}
table, th, td {
  border: 1px solid #344054;
  border-collapse: collapse;
  padding: 5px;
}
}
.soc-chat {
  .p-inputtextarea.p-inputtext {
    max-height: 42px !important;
    min-height: 42px !important;
    height: 42px !important;
    font-size: 14px;
    line-height: 20px;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .pb-2 {
    padding-bottom: .5rem !important;
  }

  .px-3 {
      padding-left: .75rem !important;
      padding-right: .75rem !important;
  }

  .pb-2 {
    padding-bottom: .5rem !important;
  }

  .pb-2 {
    padding-bottom: .5rem !important;
  }

  .p-inputtext:enabled:focus {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 8px;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF !important;
  }

  .grid {
    margin: 0px !important;
  }
}

// membership 

.loyal-membership {
  .p-component {
    margin-top: 2px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 8px 8px;
    display: flex;
    align-items: center;
  }
}

.dialog-expire{
  .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
}

.p-dialog-content {
    background: #ffffff;
    // color: #495057;
    padding: 24px !important;
}
}

.advertising-banners{

   .p-carousel-content {
    position: relative;
}

 .p-carousel-content ul.p-carousel-indicators {
   display: none !important;
}

 .p-carousel-container {
    position: relative;
}

 .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    z-index: 10;
    // top: 0;
    left: 24px;
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
    // gap: 8px;
}

 .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    right: 24px;
    // top: 0;
    z-index: 10;
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    margin: 0;
}

 .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow: none;
}

 .p-carousel .p-carousel-content .p-carousel-prev:focus {
    box-shadow: none;
}


 .hide .p-carousel-content .p-carousel-prev {
  display: none;
}

.hidden-next-button .p-carousel-content .p-carousel-next {
  display: none;
}

.p-carousel-content button.p-carousel-prev:disabled {
  display: none;
}

.p-carousel-content button.p-carousel-next:disabled {
  display: none;
}


 .show .p-carousel-content .p-carousel-next,
 .show .p-carousel-content .p-carousel-prev,
 .hide .p-carousel-content .p-carousel-next {
  color: gray;
  background: white;
  // height: 40px;
  // width: 40px;
  //top: 29%;
  //top:50%;
}

.p-carousel .p-carousel-items-container {
  display: flex;
  width: fit-content !important;
}

}

// popular-destination

.tabview-custom {
  .p-tabview {
    .p-tabview-nav {
        padding: 8px;
        background: transparent;
        border: none;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        li.p-highlight {
            .p-tabview-nav-link {
                background: #FFF !important;
                color: #1570EF !important;
                border-color: none;
                i.places-nav-icon {
                    filter: none;
                }
            }
        }
        li {
            .p-tabview-nav-link {
                background: transparent;
                font-family: Inter;
                font-size: 16px;
                font-weight: 600;
                border: none;
                border-width: 0;
                padding: 8px 16px;
                border-radius: 9999px !important;
                font-weight: 500;
                --tw-bg-opacity: 1;
                --tw-text-opacity: 1;
                border-radius: var(--corner-radius-full, 9999px);
                border: 1px solid var(--color-white-50, rgba(255, 255, 255, 0.50));
                background: var(--color-white-20, rgba(255, 255, 255, 0.20));
                color: white;
                /* 16px */
                ;
            }
            .p-tabview-nav-link:not(.p-disabled):focus {
                // box-shadow: inset 0 0 0 0.2rem #c7d2fe;
                box-shadow: none;
            }
            .p-tabview-nav-link:hover {
                // color: #c01048 !important;
                // opacity: 0.8;
                color: #1570EF !important;
            }
        }
    }
    .p-tabview-panels {
        margin-top: 4px;
        background: transparent;
        padding: 0;
    }
  }
}

.popular-destination-carousel {
  .p-carousel .p-carousel-content .p-carousel-prev {
    border: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev {
    left: 14px;
    top: 115px;
    color: gray
  }

  .p-carousel .p-carousel-content .p-carousel-next {
    right: 14px;
    top: 115px;
    color: gray;
  }

  .p-carousel .p-carousel-content .p-carousel-next {
    border: none;
    color: gray;
  }

  .p-carousel .p-carousel-content .p-carousel-next:focus {
      box-shadow: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:focus {
      box-shadow: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:hover, .airline-tickets .p-carousel .p-carousel-content .p-carousel-next:hover {
    border-color: unset;
    color: gray;
    background-color: white;
  }

  .p-carousel .p-carousel-content .p-carousel-next:hover {
    border-color: unset;
    color: gray;
    background-color: white;
  }
}

.active-responsive .p-carousel-content {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.active-responsive .p-carousel-item {
  flex: none;
  width: 286px;
}

.active-responsive-promotion .p-carousel-item {
  flex: none;
  width: 368px;
}

.result-search-icon {
  .p-dialog .p-dialog-header {
    padding: 0;
  }
}

.t-soctrip-cross-sale {
  gap: 20px !important
}

.t-soctrip-cross-sale{
  .t-body {
    gap: 16px !important;
  }

  .t-cross-sale-card{
    width: 100% !important;
    max-width: none !important;
    border-radius: 8px !important;
    padding: 0 8px;
    border: none !important;
    background-color: transparent !important;
  }

  .t-content {
    border-bottom-left-radius:  8px !important;
    border-bottom-right-radius:  8px !important;
  }

  .t-titile .t-header-title {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-family: 'Inter' !important;
    object-fit: cover !important;
  }

  .t-header .t-header-title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    font-family: 'Inter' !important;
  }
}

.cross-sale-payment-result .t-soctrip-cross-sale{
  .t-header .t-header-title {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    font-family: 'Inter' !important;
  }

  // .p-carousel-item{
  //   padding: 0 8px;
  // }

  .p-carousel-items-container .t-content {

    border-left:  1px solid  #EAECF0 !important;
    border-right:  1px solid  #EAECF0 !important;
    border-bottom:  1px solid  #EAECF0 !important;
    background:  #FFF !important;
  }

  .t-image{
    
    border-left:  1px solid  #EAECF0 !important;
    border-top:  1px solid  #EAECF0 !important;
    border-right:  1px solid  #EAECF0 !important;
   
  }

  .p-rating .p-rating-icon {
    color: #FFFF;
    font-size: 12px;
    margin-right: 4px !important;
    margin-left: 0px !important;
  }
  
  .p-rating .p-rating-icon.p-rating-icon-active {
      color: #FAC515;
    }

  .p-rating .p-rating-icon.pi-star-fill {
      color: #FAC515;
}
}




.t-soctrip-cross-sale {
  .p-carousel .p-carousel-content .p-carousel-prev {
    border: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev {
    left: 14px;
    top: 165px;
    color: gray
  }

  .p-carousel .p-carousel-content .p-carousel-next {
    right: 14px;
    top: 165px;
    color: gray;
  }

  .p-carousel .p-carousel-content .p-carousel-next {
    border: none;
    color: gray;
  }

  .p-carousel .p-carousel-content .p-carousel-next:focus {
      box-shadow: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:focus {
      box-shadow: none;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:hover, .airline-tickets .p-carousel .p-carousel-content .p-carousel-next:hover {
    border-color: unset;
    color: gray;
    background-color: white;
  }

  .p-carousel .p-carousel-content .p-carousel-next:hover {
    border-color: unset;
    color: gray;
    background-color: white;
  }
}

.t-soctrip-cross-sale .t-menu-tab .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
  background-color: #FFFF;
}
.t-soctrip-cross-sale .t-menu-tab .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  background-color: #EFF8FF !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
  box-shadow: none !important;
}

